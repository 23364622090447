<div class="page-wrapper" [ngClass]="[theme === 'dark'? 'dark' : '']">
    <div class="content">
        <div class="order-details">
            <div class="content p-0">
                <div class="page-header">
                    <div class="page-title d-flex justify-content-between w-100">
                        <h4>Reason</h4>
                        <div class="pagination">
                                <a class="btn btn-added" (click)="addReason()">Add Reason</a>

                            <button *ngIf="!showExcel" class="ms-2 Printall-label btn btn-submit" (click)="fetchDataAndCreateExcel('excel')">
                                <svg [ngStyle]="{fill: !filterForm.controls.category.value ? 'grey' : ''}"
                                    viewBox="0 0 24 24" width="18" height="18" stroke="#fff" stroke-width="2"
                                    fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                    <polyline points="6 9 6 2 18 2 18 9"></polyline>
                                    <path
                                        d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2">
                                    </path>
                                    <rect x="6" y="14" width="12" height="8"></rect>
                                </svg> Excel
                            </button>
                            <p-progressBar *ngIf="showExcel && progress>0" [value]="progress"
                                [style]="{width:'150px',height: '35px'}" />
                            <p-progressBar *ngIf="!showExcel && progress>0" [value]="progress"
                                [style]="{width:'150px',height: '35px',margin: '0 0 0 15px'}" />
                        </div>
                    </div>
                </div>


                <!-- Absentisum & attrition -->
                <div class="card">
                    <div class="card-body">
                        <div class="table-top position-relative">
                            <form [formGroup]="filterForm">
                                <div class="search-set justify-content-between w-100">
                                    <div class="search-path page-header ">
                                        <a class="btn btn-cancel" (click)="reset()">
                                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" class="css-i6dzq1">
                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                            </svg>
                                            Reset
                                        </a>
                                        <a class="btn btn-added ms-3" (click)="op.toggle($event)" icon="pi pi-image"
                                            label="Show">
                                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" class="css-i6dzq1">
                                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7">
                                                </path>
                                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z">
                                                </path>
                                            </svg>
                                            Filter Data</a>
                                    </div>
                                    <div class="filterCard flex justify-content-center" style="width: 35vw;">
                                        <p-overlayPanel #op [showCloseIcon]="true" [dismissable]='false'>
                                            <ng-template pTemplate="content">
                                                <div class="card">
                                                    <h4 class="filterHeader">Filter Data</h4>
                                                    <div class="row">

                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select PMS Status</label>
                                                                <p-dropdown formControlName="category"
                                                                    [options]="categoryList"
                                                                    placeholder="Select category"></p-dropdown>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Customer</label>
                                                                <p-dropdown formControlName="customer"
                                                                    [options]="customerList"
                                                                    placeholder="Select Customer"
                                                                    optionLabel="customerName" optionValue="id"></p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="search-path page-header ">
                                                        <a class="btn btn-primary" id="filter_search"
                                                            (click)="op.toggle(false);applyFilter()">
                                                            <svg viewBox="0 0 24 24" width="18" height="18"
                                                                stroke="currentColor" stroke-width="2" fill="none"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                class="css-i6dzq1">
                                                                <circle cx="11" cy="11" r="8"></circle>
                                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                            </svg>
                                                            Apply Filter
                                                        </a>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-overlayPanel>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="table-responsive">
                            <table class="table order-data">
                                <thead class="table-head">
                                    <tr>
                                        <ng-container>
                                            <th class="selctorder" style="position: sticky; z-index: 999;">
                                                <ng-container>
                                                    <span class="me-2 text-center date-width" style="margin-left: 10px;"
                                                        [ngClass]="{disabled: selectedData.length === 0}"
                                                        [ngStyle]="{'opacity': selectedData.length === 0 ? '0' : '1'}">
                                                        <svg matTooltip="Delete selected"
                                                            *ngIf="selectedData.length > 0"
                                                            (click)="showConfirmationDialog('')" width="20" height="20"
                                                            viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M5 20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V8H21V6H17V4C17 3.46957 16.7893 2.96086 16.4142 2.58579C16.0391 2.21071 15.5304 2 15 2H9C8.46957 2 7.96086 2.21071 7.58579 2.58579C7.21071 2.96086 7 3.46957 7 4V6H3V8H5V20ZM9 4H15V6H9V4ZM8 8H17V20H7V8H8Z"
                                                                fill="#EA5455" />
                                                            <path d="M9 10H11V18H9V10ZM13 10H15V18H13V10Z"
                                                                fill="#EA5455" />
                                                        </svg>
                                                    </span>
                                                </ng-container>
                                            </th>
                                            <th>Action</th>
                                            <th>Category Type</th>
                                            <th class="col-width">Reason</th>
                                            <th>Issue Type</th>
                                            <th>Status</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="!loader">
                                        <tr *ngFor="let row of reasonData">
                                            <td class="selctorder">
                                                <mat-checkbox (change)="onCheckboxChange($event, row)"
                                                    [checked]="isSelected(row)"></mat-checkbox>
                                            </td>
                                            <td>
                                                <a class="me-3">
                                                    <img matTooltip="Delete" (click)="showConfirmationDialog(row)"
                                                        src="../../../../assets/img/icons/delete.svg" alt="img">
                                                </a>
                                                <a class="ms-3" (click)="updateReason(row, true)">
                                                    <svg style="width: 22px;fill: #a0a0a0;" matTooltip="Update"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 512 512">
                                                        <path
                                                            d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z" />
                                                    </svg>
                                                </a>
                                            </td>
                                            <td>{{ row.categoryType }}</td>
                                            <td class="col-width">{{ row.reason }}</td>
                                            <td>{{ row.issueType }}</td>
                                            <td>
                                                <span class="text-success" *ngIf="row?.status == 1">Active</span>
                                                <span class="text-danger" *ngIf="row?.status == 0">Inactive</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="reasonData?.length == 0">
                                            <div class="no-order pt-4 pb-4">
                                                <p class="margin-0">No data found</p>
                                            </div>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="pagination search-set justify-content-end flex-end w-100">
                    <mat-paginator class="demo-paginator" (page)="pageNavigations($event)" [length]="length"
                        [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 50, 100]" aria-label="Select page">
                    </mat-paginator>

                </div>
            </div>
        </div>
    </div>
</div>

<p-toast/>
<p-confirmDialog  [position]="'top'" rejectButtonStyleClass="p-button-outlined">
</p-confirmDialog>