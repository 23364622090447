import { Component, Inject } from '@angular/core';
import { MasterService } from '../../master.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { error } from 'jquery';

interface IssueType {
  label: string;
  value: string;
}

@Component({
  selector: 'app-update-reason',
  templateUrl: './update-reason.component.html',
  styleUrls: ['./update-reason.component.css']
})
export class UpdateReasonComponent {
  theme = 'dark';
  fileType: any;
  progress = 0;
  message = '';
  selectedFiles?: FileList;
  selectedFile: File | null = null;
  currentFile?: File;
  userRole: any;
  customerList: any[] = [];
  clientId: any;

  updateForm = new FormGroup({
    categoryType: new FormControl(''),
    reason: new FormControl(''),
    issueType: new FormControl(''),
    status: new FormControl('')

  })
  issueTypes: IssueType[] = [
    { label: 'ASC', value: 'ASC' },
    { label: 'NON-ASC', value: 'NON-ASC' }
  ];
  constructor(private masterService: MasterService, public messageService: MessageService,
    private dialogRef: MatDialogRef<UpdateReasonComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {

    const userDetails = JSON.parse(localStorage.getItem('user_details') || '[]');
    this.userRole = userDetails.role

    const customerList = JSON.parse(localStorage.getItem('customerList') || '[]');
    this.clientId = JSON.parse(localStorage.getItem('clientId')!);
    this.customerList = customerList ? customerList : [];
    this.customerList.forEach((el: any) => {
      if (el.id === this.clientId) {
        // this.filterForm.controls.customer.setValue(el.customerName)
      }
    })
    const patchedData = {
      ...this.data,
      categoryType: this.data.updateData.categoryType,
      reason: this.data.updateData.reason,
      issueType: this.data.updateData.issueType
    }
    this.updateForm.patchValue(patchedData)
    console.log(patchedData)

  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
    if (event.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.progress = 0;
      this.selectedFiles = undefined
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Uploaded file must be in excel format.',
      });
      return
    }
  }

  upload(type: any): void {
    this.progress = 0;
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;
        this.masterService.uploadReasonData(this.currentFile, 'REASON', this.clientId, this.userRole).subscribe({
          next: (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
              if (this.progress == 100) {
                this.progress = 99;
              }
            } else if (event instanceof HttpResponse) {
              this.message = event.body.message;
              this.progress = 0;
              this.dialogRef.close();
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Reasons Uploaded Successfully',
              });
            }
          },
          error: (err: any) => {
            console.log(err);
            this.progress = 0;

            if (err.error && err.error.message) {
              this.message = err.error.message;
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: err.error.message,
              });
            } else {
              this.message = 'Could not upload the file!';
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: this.message,
              });
            }

            this.currentFile = undefined;
          },
        });
      }
    }
  }

  onSubmit(data: any) {
    const dataToSend = {
      "categoryType": this.updateForm.controls.categoryType.value,
      "reason": this.updateForm.controls.reason.value,
      "issueType": this.updateForm.controls.issueType.value,
      "status": 1,
      "customerId": this.data.updateData.customerId,
      "id": this.data.updateData.id
    }

    this.masterService.updateReason(dataToSend).subscribe((res: any) => {
      this.dialogRef.close();
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `Reason updated successfully.`,
      },)
    },
      error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Reason update failed.`,
        });
      })
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
