import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { FormatForExcelNearmissPipe } from 'src/app/_pipe/format-for-excel-nearmiss.pipe';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/shared.service';
import * as XLSX from 'xlsx';
import { MasterService } from '../../master.service';
import { UpdateReasonComponent } from '../update-reason/update-reason.component';

interface Category {
 category: String;
}
@Component({
  selector: 'app-reason',
  templateUrl: './reason.component.html',
  styleUrls: ['./reason.component.css']
})
export class ReasonComponent {
  minDate: any
  maxDate: any;

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  pageEvent: any;
  totalRecords!: number;
  allCheckedChange: Subject<boolean> = new Subject<boolean>();
  allChecked: boolean = false;
  loader: boolean = false;
  warehouseList: any[] = [];
  customerList: any[] = [];
  categoryList: Category | any;
  selectedWarehouse: any;
  selectedCustomer: any;
  userRole: any;
  warehouseId: any;
  userDetails: any;
  pmsData: any = [];
  SelectedStartDate: any;
  SelectedEndDate: any;
  isChecked: boolean = true;
  processedAbsentismList: any = [];
  confirmationDialogDisplay: boolean = false;
  progress: number = 0;
  showExcel = false;
  showReason = false;
  data: any[] = [];
  totalCalls: any;
  chunkSize: any;
  selectedData: any[] = [];
  theme = 'dark'
  reasonData: any = [];
  clientId: any
  constructor(private fb: FormBuilder, public dialog: MatDialog, private auth: AuthService, private datePipe: DatePipe, private messageService: MessageService,
    private sharedService: SharedService, private formatNearmess: FormatForExcelNearmissPipe,
    private masterService: MasterService, private confirmationService: ConfirmationService) {

  }

  ngOnInit(): void {
    this.clientId = JSON.parse(localStorage.getItem('clientId')!);
    const customerList = JSON.parse(localStorage.getItem('customerList') || '[]');
    this.customerList = customerList ? customerList : [];
    this.customerList.forEach((el:any)=>{
      if(el.id === this.clientId){
        this.filterForm.controls.customer.setValue(el.id)
      }
    })

    this.categoryList = [
      {label: 'GI', value: 'GI'},
      {label: 'ORDER', value: 'ORDER'},
      {label: 'PMS', value: 'PMS'}
    ]
    const obj = this.setFilter();
    this.getReasonInfo(obj, this.pageIndex, this.pageSize);
  }

  filterForm = this.fb.group({
    category: new FormControl(''),
    customer: new FormControl()
  });

  getReasonInfo(filter: any, pageIndex: any, pageSize: any) {
    this.masterService.getReasonInfo(filter, pageIndex, pageSize).subscribe((res: any) => {
      if (res != null) {
        this.loader = false;
        this.length = res?.responseObject.totalElements;
        this.totalRecords = this.length;
        this.reasonData = res?.responseObject?.content;
        this.chunkSize = 250;
        this.totalCalls = Math.ceil(this.totalRecords / this.chunkSize);

        this.totalRecords = this.length;
      }
    })
  }
  reset() {
    this.filterForm.reset();
    const obj = this.setFilter()
    this.getReasonInfo(obj, 0, this.pageSize);
    this.progress = 0
    this.showExcel = false
  }

  applyFilter() {
    const obj = this.setFilter();
    // const categoryType = this.filterForm.get('category')?.value;

   this.getReasonInfo(obj, this.pageIndex, this.pageSize);
  }

  setFilter() {
    const obj = [];
    if (this.filterForm.controls.category.value) {
      obj.push({
        "key": "CATEGORY",
        "value": this.filterForm.get('category')?.value
      })
    }
    if (this.filterForm.controls.customer.value ? this.filterForm.controls.customer.value : this.clientId) {
      obj.push({
        "key": "CUSTOMER_ID_IN",
        "value": this.filterForm.controls.customer.value ? this.filterForm.controls.customer.value : this.clientId
      })
    }

    return obj;
  }

  pageNavigations(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageEvent, this.length, this.pageIndex, this.pageIndex)
    this.allChecked = false;
    const obj = this.setFilter();
    this.getReasonInfo(obj, this.pageIndex, this.pageSize)
  }

  addReason(){
    let dialogRef = this.dialog.open(UpdateReasonComponent, {
      panelClass: 'custom-modalbox',
      data: {
        isUpdate: false
      }
    })
  }

  updateReason(data:any, isUpdate: boolean){
    let dialogRef = this.dialog.open(UpdateReasonComponent, {
      panelClass: 'custom-modalbox',
      data: {
        updateData: data,
        isUpdate: isUpdate
      }
    });
    console.log(data)
    dialogRef.afterClosed().subscribe((result) => {
      const obj = this.setFilter()
      this.getReasonInfo(obj, 0, this.pageSize);
    })
  }
  fetchDataAndCreateExcel(type: any) {
    if (type === 'excel') {
      this.showExcel = true
      // this.showReason =false
    }

    if (this.length > 50000) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Record count has surpassed the 50,000 limit. Please refine your search criteria.',
      });
      return
    }
    this.fetchDataInPages(type);
  }

  showConfirmationDialog(data:any): void {
    this.selectedData = this.selectedData || [];
      if(this.selectedData || data){
        this.selectedData.push(data)
      }
    
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected records?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteSelected();
      },
      reject: () => {

      }
    });
  }

  onCheckboxChange(event: any, row: any): void {
    if (event.checked) {
      this.selectedData.push(row);
    } else {
      this.selectedData = this.selectedData.filter(item => item !== row); // Remove deselected row
    }
  }

  isSelected(row: any): boolean {
    return this.selectedData.includes(row);
  }

  deleteSelected(): void {
    if (this.selectedData?.length > 0) {
      const id = this.selectedData.map((row: any) => row.id).join(',');

      this.masterService.deleteReasonsDetails(id).subscribe({
        next: (response: any) => {
          this.pmsData = this.pmsData.filter((row: any) => !this.selectedData.some(selectedRow => selectedRow.primaryKey === row.primaryKey));
          this.selectedData = [];
          const obj = this.setFilter();
          this.getReasonInfo(obj, this.pageIndex, this.pageSize)
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Selected records have been deleted successfully.' });
        },
        error: (error: any) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete the selected records.' });
        }
      });
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'No records selected for deletion.' });
    }
  }
  fetchDataInPages(type: any) {
    const obj = this.setFilter()
    this.progress = this.progress === 0 ? 1 : this.progress
    this.masterService.getReasonInfo(obj, this.pageIndex, this.chunkSize).subscribe((res: any) => {
      if (res?.responseObject?.content?.length > 0) {
        this.data = this.data.concat(res?.responseObject?.content);
        console.log(this.data);
        this.progress = Math.round(((this.pageIndex + 1) / this.totalCalls) * 100);
        this.pageIndex++;

        if (this.pageIndex < this.totalCalls) {
          this.fetchDataInPages(type);
        } else {
          this.createExcel(type);
        }
      }
      else {
        this.progress = Math.round(((this.pageIndex + 1) / this.totalCalls) * 100);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No data found',
        });
        return
      }
    });
  }

  createExcel(type: any) {
    if (this.data.length > 0 && type === 'excel') {
      const headers = [
        'S.No', 'Category Type', 'Reason', 'Issue Type', 'Status'
      ];

      const filteredData = [headers];

      const filteredDataSource = this.data;

      filteredDataSource.forEach((element: any, index: number) => {
        const rowData = [
          index + 1,
          element.categoryType,
          element.reason,
          element.issueType,
          element.status
        ];

        filteredData.push(rowData);
      });

      const ws = XLSX.utils.aoa_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();

      const todayDateTime = new Date().toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });

      this.progress = 0;
      this.pageIndex = 0;
      this.data = [];
      this.showExcel = false;

      const filename = `Reason ${todayDateTime}.xlsx`;

      XLSX.utils.book_append_sheet(wb, ws, 'Reason');
      XLSX.writeFile(wb, filename);
    }
  }

}
