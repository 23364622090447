<div class="page-wrapper" [ngClass]="[theme === 'dark'? 'dark' : '']">
    <div class="content">
        <div class="order-details">
            <div class="content p-0">
                <div class="page-header">
                    <div class="page-title d-flex justify-content-between w-100">
                        <h4>Calculation</h4>
                    </div>
                </div>

                <!-- Calculation -->
                <div class="card">
                    <div class="card-body">
                        <div class="table-top position-relative">
                            <form [formGroup]="filterForm">
                                <div class="search-set justify-content-between w-100 ">
                                    <div class="search-path page-header ">
                                        <a class="btn btn-cancel" (click)="reset()">
                                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" class="css-i6dzq1">
                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                            </svg>
                                            Reset
                                        </a>
                                        <a class="btn btn-added ms-3" (click)="op.toggle($event)" icon="pi pi-image"
                                            label="Show">
                                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor"
                                                stroke-width="2" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round" class="css-i6dzq1">
                                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7">
                                                </path>
                                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z">
                                                </path>
                                            </svg>
                                            Filter Data</a>

                                    </div>
                                    <div class="filterCard flex justify-content-center " style="width: 35vw;">
                                        <p-overlayPanel #op [showCloseIcon]="true" [dismissable]='false'>
                                            <ng-template pTemplate="content">
                                                <div class="card custom-card">
                                                    <h4 class="filterHeader">Filter Data</h4>
                                                    <div class="row">

                                                        <div class="col-lg-6 col-sm-6">
                                                            <div class="form-group">
                                                                <label>Select Type</label>
                                                                <p-dropdown formControlName="logicType" [options]="type"
                                                                    placeholder="Select Type">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6">
                                                            <div class="form-group">
                                                                <label>Select File Type</label>
                                                                <p-dropdown formControlName="fileType"
                                                                    [options]="fileType" placeholder="Select Type">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label>Select Customer</label>
                                                                <p-dropdown formControlName="customerName"
                                                                    [options]="customerList"
                                                                    placeholder="Select Customer"
                                                                    optionLabel="customerName"></p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="search-path page-header ">
                                                        <a class="btn btn-primary" id="filter_search"
                                                            (click)="op.toggle(false);applyFilter()">
                                                            <svg viewBox="0 0 24 24" width="18" height="18"
                                                                stroke="currentColor" stroke-width="2" fill="none"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                class="css-i6dzq1">
                                                                <circle cx="11" cy="11" r="8"></circle>
                                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                            </svg>
                                                            Apply Filter
                                                        </a>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-overlayPanel>
                                    </div>
                                </div>
                            </form>
                            <!-- <div class="pagination search-path page-header d-flex justify-content-end">
                                <a class="btn btn-added">
                                    Add
                                </a>
                            </div> -->
                        </div>

                        <div class="table-responsive">
                            <table class="table order-data">
                                <thead class="table-head">
                                    <tr>
                                        <ng-container>
                                            <th>Action</th>
                                            <th>Client Name</th>
                                            <th>File Type</th>
                                            <th>Schema Column Name</th>
                                            <th>Client Column Name</th>
                                            <th>Default Formula</th>
                                            <th>Formula</th>
                                        </ng-container>

                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container>
                                        <ng-container *ngIf="!loader">
                                            <tr *ngFor="let row of calculationData">
                                                <td>
                                                    <button class="btn btn-submit me-2" *ngIf="!row?.formula" (click)="updateCalculation(row,'add')">Add</button>
                                                    <button class="btn btn-submit me-2" *ngIf="row?.formula" (click)="updateCalculation(row,'update')">Update</button>
                                                    <button class="btn btn-cancel" *ngIf="row?.formula" (click)="resetRecord(row)">Use Default</button>
                                                </td>
                                                <td>{{row.clientName}}</td>
                                                <td>{{row.fileType }}</td>
                                                <td>{{row.tblColumnName}}</td>
                                                <td>{{ row.clientColumnName }}</td>
                                                <td class="col-width">{{row?.defaultFormula}}</td>
                                                <td class="col-width">{{row?.formula}}</td>
                                                <!-- <td><p-tag  severity="success" value="Enable" [rounded]="true" /></td> -->
                                            </tr>
                                            <tr *ngIf="calculationData?.length == 0">
                                                <div class="no-order pt-4 pb-4">
                                                    <p class="margin-0">No data found</p>
                                                </div>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="pagination search-set justify-content-end flex-end w-100">
                    <mat-paginator class="demo-paginator" (page)="pageNavigations($event)" [length]="length"
                        [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 30, 50, 100]" aria-label="Select page">
                    </mat-paginator>

                </div>
            </div>
        </div>
    </div>
</div>