import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MasterService } from '../../master.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { OrderService } from 'src/app/shared/services/order-service';

@Component({
  selector: 'app-update-calculation',
  templateUrl: './update-calculation.component.html',
  styleUrls: ['./update-calculation.component.css']
})
export class UpdateCalculationComponent {
  id: any;
  clientId: any;
  isDefaultChecked: boolean = false;
  type: any;
  calculationReport: any;
  updateForm = new FormGroup({
    isDefaultChecked: new FormControl(false),
    fileType: new FormControl({ value: '', disabled: true }),
    tblColumnName: new FormControl({ value: '', disabled: true }),
    clientColumnName: new FormControl(''),
    defaultFormula: new FormControl({ value: '', disabled: true }),
    formula: new FormControl('')
  })
  outputResult:any;
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder,private router: Router,private messageService: MessageService,
   private dialogRef: MatDialogRef<UpdateCalculationComponent>,
  private masterService: MasterService, private OrderService: OrderService){
    this.calculationReport = null
  }
  ngOnInit(): void {
    this.clientId = JSON.parse(localStorage.getItem('clientId')!);
    if (this.data['component'] === 'calculation') {
      console.log(this.data);
      if (this.data && this.data.updateData) {
        this.id = this.data.updateData?.id;
        this.type = this.data.type
        this.updateForm.patchValue({
          // clientName: this.data.updateData.clientName,
          fileType: this.data.updateData.fileType,
          tblColumnName: this.data.updateData.tblColumnName,
          defaultFormula: this.data.updateData.defaultFormula,
          formula: this.data.updateData.formula,
          clientColumnName: this.data?.updateData.clientColumnName
        });
      }
    }
  }  

setDefaultFormulae(event: any): void {
  const isChecked = event.target.checked; 
  const defaultFormulaControl = this.updateForm.get('defaultFormula');
  const formulaControl = this.updateForm.get('formula');

  if (isChecked && defaultFormulaControl) {
    const defaultFormula = defaultFormulaControl.value || '';
    formulaControl?.setValue(defaultFormula);
  } else {
    formulaControl?.setValue(this.data.updateData.formula); 
  }
}

testCalculation(){
  this.masterService.testCalculation(this.clientId).subscribe((res:any)=>{
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Test Calculation Successfully',
    });
  })
}
  
  closeDialog(){
    this.dialogRef.close();
  }

  onSubmit(updateForm: any){
    // console.log(updateForm);
    const updateObj = {
      "id": this.data.id ? this.data.id : null,
      "logicType": "user_logic",
      "fileType": this.updateForm.controls.fileType.value,
      "clientName": this.data.updateData.clientName,
      "tblColumnName": this.updateForm.controls.tblColumnName.value,
      "formula": this.updateForm.controls.formula.value,
      "customerId": this.data.clientId,
      "status": 1
    }
    const AddObj = {
      "id": null,
      "logicType": "user_logic",
      "fileType": this.updateForm.controls.fileType.value,
      "clientName": this.data.updateData.clientName,
      "tblColumnName": this.updateForm.controls.tblColumnName.value,
      "formula": this.updateForm.controls.formula.value,
      "customerId":this.data.clientId,
      "status": 1
    }

    this.clientColName()
    if(this.updateForm.controls.formula.value?.trim()){
      if(this.type === 'add'){
        this.masterService.addCalculation(AddObj).subscribe((res:any) => {
          if(res){
            this.dialogRef.close();
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: `Calculation added successfully`,
            });
          }
        },(error:any)=>{
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error.responseMessage,
          });
        })
      }
      else{
        if(this.updateForm.controls.formula.value === this.updateForm.get('defaultFormula')?.value){
          this.masterService.addCalculation(AddObj).subscribe((res:any) => {
            if(res){
              this.dialogRef.close();
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: `Calculation added successfully`,
              });
            }
          },(error:any)=>{
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error.error.responseMessage,
            });
          })
        }
        else{
          console.log(updateObj)
          this.masterService.updateCalculation(updateObj).subscribe((res:any) => {
            if(res){
              this.dialogRef.close();
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: `Calculation updated successfully`,
              });
            }
          },(error:any)=>{
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error.error.responseMessage,
            });
          })
        }
    }
    }
  }

  private clientColName(){
    const dataToSend = {
      clientId: this.clientId,
      fileType: this.data?.updateData?.fileType === 'GR' ? 'GR280' :
          this.data?.updateData?.fileType === 'GI' ? 'GI90' : 
          this.data?.updateData?.fileType,
      status: 1,
      fileFieldMappings: [
        {
          id: this.data?.clientFileTypeId ? this.data?.clientFileTypeId : null,
          headerName: this.updateForm.controls.clientColumnName.value,
          fieldName: this.data?.updateData?.tblColumnName,
          fieldType: 'String',
          status: 1
        }
      ]
    }

    const clientColumnName = this.updateForm.controls.clientColumnName.value;
    if (clientColumnName && clientColumnName.trim() !== '') {
      this.OrderService.addFileFieldMappingDetails(dataToSend).subscribe((response) => {
        if (response) {
          console.log(dataToSend);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Client column name updated`,
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Client column name update failed`,
          });
        }
      });
    }
  }

  testCalculationProcess(){
    this.masterService.testCalculationProcess(this.clientId,this.data.updateData.fileType).subscribe((res:any)=>{
      this.calculationReport = res
    })
  }

}
